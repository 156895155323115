<template>
  <div>
    <label style="margin-bottom: 5px"
      ><i :class="{ icon }" /> {{ label }}</label
    >
    <v-select
      :options="lista"
      :multiple="multiple"
      v-model="val"
      :reduce="(u) => u.value"
    />
  </div>
</template>

<script>
import EntidadeLib from "../../libs/EntidadesLib";
export default {
  components: {},
  props: {
    multiple: {
      type: Boolean,
      default: true,
    },
    tipo: {
      type: String,
      default: "medico",
    },
    label: {
      type: String,
      default: "Médico(s)",
    },
    icon: {
      type: String,
      default: "fa fa-user-md",
    },
    onDemand:{
      type: Boolean,
      default:false,
    }
  },
  mounted() {
    this.carregar();
  },
  data() {
    return {
      lista: [],
      val: [],
    };
  },
  watch: {
    val: {
      deep: true,
      handler() {
        this.$emit("change", this.val);
      },
    },
  },
  computed: {},
  methods: {
    async carregar() {
      this.lista = (
        await EntidadeLib.getSimples({
          tipo: [this.tipo],
        })
      ).data
        .sort((a, b) => {
          return a.razao_social > b.razao_social ? 1 : -1;
        })
        .map((i) => {
          return {
            value: i,
            label: i.razao_social,
          };
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>