<template>
  <div class="container">
    <div class="row mt-4 ml-2 mr-2">
      <div class="col-12 col-sm-4">
        <label><b-icon-calendar /> Periodo</label>
        <div class="row">
          <div class="col-6 pr-1">
            <b-input
              type="date"
              style="padding:3px"
              v-model="filtros.periodo.dt_i"
            />
          </div>
          <div class="col-6 pl-1">
            <b-input
              type="date"
              style="padding:3px"
              v-model="filtros.periodo.dt_f"
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-4">
        <select-empresa-clinica
          @empresa="empresaSelect"
          :withConsultorio="false"
          :multiple="true"
        />
      </div>
      <div class="col-12 col-sm-4">
        <select-entidades-simples
          @change="selectMedicos"
          :multiple="false"
          :icon="'fas fa-user-md'"
        />
      </div>
      <div v-if="filtros.medico" class="col-12 pt-3">
        <hr />
        <label>Selecione o(s) produto(s)/exame(s)/consulta(s)</label>
        <v-select
          :options="exames"
          v-model="filtros.exame"
          :reduce="(it) => it.value"
        />
        <div class="d-flex justify-content-end">
          <b-btn variant="warning" class="mt-1" @click="carregarVendas"
            ><b-icon-search /> Buscar</b-btn
          >
        </div>
        <hr />
      </div>
    </div>
    <div v-if="loading">
      <b-spinner /> <br />
      aguarde...
    </div>
    <div class="row" v-if="!loading && lista && lista.length <= 0">
      <div class="col-12 text-center p-4">Nenhum registro encontrado!</div>
    </div>
    <div class="row" v-if="!loading && lista && lista.length > 0">
      <div class="col-12">
        <b-card>
          <b-card-title class="text-left">Correção</b-card-title>
          <b-card-body>
            <div class="d-flex justify-content-between">
              <div style="min-width: 300px" class="d-flex">
                <div style="min-width: 150px" label="">
                  <label>Valor Custo</label><br />
                  <money
                    class="form-control text-center"
                    v-bind="moneyMask"
                    v-model="vl_custo"
                  />
                </div>
                <div style="min-width: 150px" label="Valor Venda" class="ml-2">
                  <label>Valor Venda</label><br />
                  <money
                    class="form-control text-center"
                    v-bind="moneyMask"
                    v-model="vl_venda"
                    :disabled="true"
                  />
                </div>
              </div>
              <b-btn
                style="min-width: 150px"
                size="lg"
                variant="success"
                @click="corrigir"
                :disabled="corrigindo"
                ><b-icon-check-circle v-if="!corrigindo" />
                <b-spinner small v-if="corrigindo" />
                Corrigir</b-btn
              >
            </div>
          </b-card-body>
        </b-card>
        <hr />
        <div class="text-left">
          <b-btn variant="info" @click="checkAll()"
            ><i class="fa fa-check-double mr-1" /> Marcar/Desmarcar Todos</b-btn
          >
        </div>
        <b-table
          :items="lista"
          :fields="[
            { key: 'sel', label: '' },
            { key: 'cod' },
            { key: 'data' },
            { key: 'cliente', class: 'text-left' },
            { key: 'item_nome', class: 'text-left' },
            { key: 'usuario' },
            { key: 'vl_total', label: 'Venda' },
            { key: 'vl_custo', label: 'Custo' },
          ]"
          :current-page="currentPage"
          :per-page="perPage"
        >
          <template #cell(sel)="row">
            <b-checkbox v-model="row.item.sel" />
          </template>
          <template #cell(cod)="row">
            {{ row.item.id }} / {{ row.item.vendaitemId }}
          </template>
          <template #cell(data)="row">
            {{ row.item.data | moment("DD/MM/YYYY HH:mm") }}
          </template>
          <template #cell(vl_total)="row">
            {{ row.item.vl_total | currency }}
          </template>
          <template #cell(vl_custo)="row">
            {{ row.item.vl_custo | currency }}
          </template>
        </b-table>
        <div class="pl-5 pr-5">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import SelectEmpresaClinica from "../../components/common/SelectEmpresaClinica.vue";
import SelectEntidadesSimples from "../../components/common/SelectEntidadesSimples.vue";
import CaixasLib from "../../libs/CaixasLib";
import ExamesLib from "../../libs/ExamesLib";
import VendaLib from "../../libs/VendaLib";
export default {
  components: { SelectEmpresaClinica, SelectEntidadesSimples },
  props: {},
  mounted() {},
  data() {
    return {
      currentPage: 0,
      totalRows: 0,
      perPage: 20,
      filtros: {
        periodo: {
          dt_i: moment().format("YYYY-MM-DD"),
          dt_f: moment().format("YYYY-MM-DD"),
        },
        empresas: null,
        medico: null,
        exame: null,
      },
      lista: null,
      loading: false,
      exames: [],
      examesRaw: [],

      vl_venda: 0,
      vl_custo: 0,
      selecionados: [],
      corrigindo: false,
    };
  },
  watch: {
    "filtros.medico": function() {
      this.carregarExames();
    },
    "filtros.exame": function() {
      if (this.filtros.exame) {
        this.vl_custo = this.examesRaw.find(
          (x) => x.id == this.filtros.exame
        ).valor_custo;
        this.vl_venda = this.examesRaw.find(
          (x) => x.id == this.filtros.exame
        ).valor_venda;
      }
    },
  },
  computed: {},
  methods: {
    async corrigir() {
      this.$swal({
        title: "Tem certeza que deseja prosseguir?",
        text:
          "Todos os lançamentos selecionados sofreram alteração. Não será possivel desfazer esta operação, apenas com uma nova!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Alterar!",
        cancelButtonText: "Cancelar",
      }).then(async (x) => {
        if (x.isConfirmed) {
          this.corrigindo = true;
          try {
            let obj = {
              lista: this.lista
                .filter((x) => x.sel === true)
                .map((x) => x.vendaitemId),
              vl_venda: this.vl_venda,
              vl_custo: this.vl_custo,
            };
            let validacao = await VendaLib.corrigirLancamentosValidacao(obj);
            console.log("resultValidacao", validacao);
            if (validacao && validacao.success == false) {
              let msg = [];
              if (validacao.messages && validacao.messages.length > 0) {
                for (let ms of validacao.messages) {
                  let fnd = this.lista.find(
                    (x) => x.vendaitemId == ms.vendaItemId
                  );
                  if (fnd) {
                    msg.push(
                      `O lançamento código ${fnd.id} / ${fnd.vendaitemId} do cliente ${fnd.cliente} consta em uma fatura para pagamento de médico codigo ${ms.pagamentoId}`
                    );
                  }
                }
              }
              if (msg.length <= 0) {
                msg.push(
                  "Algum lançamento já consta em pagamento de médico. Verifique"
                );
              }
              this.$swal({
                title: "Atenção",
                icon: "error",
                html: `<div class='text-left'>${msg.join("<hr/>")}</div>`,
              });
            } else {
              let res = await VendaLib.corrigirLancamentos(obj);

              console.log("res correção", res);
              if (res && res.success) {
                this.$swal("Concluido!", "", "success");
                this.carregarVendas();
              }
            }
          } catch (err) {
            console.log("erro on corrigir", err);
          }
          this.corrigindo = false;
        }
      });
    },
    checkAll() {
      this.lista.forEach((it) => {
        it.sel = it.sel === true ? false : true;
      });
      this.$forceUpdate();
    },
    async carregarExames() {
      console.log("med", this.filtros.medico);
      if (
        this.filtros.medico &&
        this.filtros.medico.id &&
        this.filtros.medico.id > 0
      ) {
        let exs = await ExamesLib.get("Consulta|Exame", this.filtros.medico.id);
        this.examesRaw = exs;
        // console.log("exs", exs);

        this.exames = exs.map((it) => ({
          value: it.id,
          label: it.nome,
        }));
      }
    },
    empresaSelect(emp) {
      console.log("empresas", emp);
      this.filtros.empresas = emp;
    },
    selectMedicos(med) {
      this.filtros.medico = med;
    },
    async carregarVendas() {
      if (!this.filtros.medico) {
        this.$swal(
          "Atenção",
          "Informe um médico para poder buscar os lançamentos!",
          "error"
        );
        return;
      }
      if (!this.filtros.exame) {
        this.$swal(
          "Atenção",
          "Informe um exame/consulta/produto para poder buscar os lançamentos!",
          "error"
        );
        return;
      }

      this.loading = true;
      this.lista = [];
      try {
        let result = [];

        let lista = await CaixasLib.listagemCaixa({ filtro: this.filtros });
        console.log("list", lista, result);

        if (lista && lista.lancamentos && lista.lancamentos.length > 0) {
          for (let it of lista.lancamentos) {
            this.lista.push(
              ...it.lista.map((it) => ({
                sel: true,
                ...it,
              }))
            );
            this.totalRows = this.lista.length;
            this.currentPage = 1;
          }

          console.log("lista", this.lista);
        }
      } catch (error) {
        console.log("Erro on carregarProdutos", error);
      }
      this.loading = false;
    },
    busar() {},
  },
};
</script>

<style lang="scss" scoped></style>
